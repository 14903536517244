<template>
  <div>
    <div class="w-100">
      <el-button type="success" plain @click="routerList()">
        <el-icon><ArrowLeft /></el-icon>Liste
      </el-button>
    </div>

    <edit :database="this.$route.params.db" :table_name="this.$route.params.table_name" :id="this.$route.params.id"></edit>
  </div>
</template>

<script>
import edit from "../components/edit/edit.vue";
import { ArrowLeft } from "@element-plus/icons-vue";
export default {
  components: { edit, ArrowLeft },
  methods: {
    routerList() {
      this.$router.push("/list/" + this.$route.params.db + "/" + this.$route.params.table_name);
    },
  },
};
</script>

<style></style>
